import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const container = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
`

export const supervisor_container = css`
	display: flex;
	@media (min-width: ${breakpoints.tablet}px) {
		width: calc(58% - 20px);
	}
`

export const supervisor_img = css`
	width: 28%;
	img {
		width: 100%;
		border-radius: 12px;
		overflow: hidden;
	}
`

export const book_container = css`
	display: flex;
	padding: 20px;
	margin-top: 20px;
	border-radius: 12px;
	background: ${palette.grey['600']};
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
		width: calc(42% - 20px);
	}
`

export const book_text = css`
	margin-bottom: 10px;
`

export const book_img = css`
	width: 32%;
	img {
		width: 100%;
		border-radius: 12px;
		overflow: hidden;
	}
`

export const book_contents = css`
	width: 72%;
	margin-left: 20px;
	display: flex;
	flex-direction: column;
`

export const contents = css`
	width: 72%;
	margin-left: 20px;
`

export const text = css`
	margin-top: 10px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}
`

export const category_text = css`
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 2rem;
	}
`

export const title_text = css`
	font-size: 2.4rem;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 3.2rem;
	}
`

export const desc_text = css`
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 2rem;
	}
`

export const info_text = css`
	font-weight: 500;
	font-size: 1.4rem;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 1.6rem;
	}
`

export const amazon_link = css`
	margin-top: auto;
	padding: 10px 12px;
	min-height: 32px;
	font-size: 1.2rem;
`

export const link_icon = css`
	:after {
		position: absolute;
		right: 12px;
	}
`