import React from 'react'
import Icon from '~/components/atoms/Icon'
import { link } from './styles'

const LinkIcon = ({ ...props }) => {
	return (
		<Icon css={link} {...props} />
	)
}

export default LinkIcon
