import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Typography from '~/components/atoms/Typography'
import Button from '~/components/atoms/Button'
import LinkIcon from '~/components/molecules/LInkIcon'

import {
	amazon_link,
	book_container,
	book_contents, book_img, book_text, category_text,
	container,
	contents, desc_text, info_text, link_icon,
	supervisor_container,
	supervisor_img, text, title_text,
} from './styles'

const Supervisor = ({ ...props }) => {
	const { supervisor_01, supervisor_02 } = useStaticQuery<GatsbyTypes.SupervisorQuery>(graphql`
		query Supervisor {
			supervisor_01: file(relativePath: {eq: "supervisor_01.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		    supervisor_02: file(relativePath: {eq: "supervisor_02.jpg"}) {
			    childImageSharp {
			      fluid(maxWidth: 500) {
			        ...GatsbyImageSharpFluid_withWebp
			      }
			    }
		    }
		}
	`)
	return (
		<div css={container} {...props}>
			<figure css={supervisor_container}>
				<div css={supervisor_img}>
					<Img fluid={supervisor_01.childImageSharp.fluid} />
				</div>
				<figcaption css={contents}>
					<Typography color='textSecondary' css={category_text}>アプリのレシピ監修</Typography>
					<Typography color='textPrimary' css={[text, title_text]}>早坂 信哉</Typography>
					<Typography variant='h6' css={[text, desc_text]}>医師・博士（医学）・温泉療法専門医</Typography>
					<Typography variant='body2' css={[text, info_text]}>20年にわたる研究で、のべ3万8千人の入浴を調査してきた第一人者。家庭のお風呂や銭湯だけでなく、「温泉療法専門医」として温泉の医学的効果も研究。<br/>※監修ドクターは、入浴剤等の販売商品には携わっておりません。掲載商品への保証や購入などを推薦するものではありません。</Typography>
				</figcaption>
			</figure>

			<figure css={book_container}>
				<div css={book_img}>
					<Img fluid={supervisor_02.childImageSharp.fluid} />
				</div>
				<figcaption css={book_contents}>
					<Typography variant='h6' color='textPrimary' css={book_text}>最高の入浴法~お風呂研究20年、3万人を調査した医師が考案</Typography>
					<a href='https://www.amazon.co.jp/dp/4479784497/ref=cm_sw_em_r_mt_dp_CM0SWM283PKGG2EZSF3H' target='_blank' rel='noopener noreferrer'>
						<Button css={amazon_link}>Amazonで見る<LinkIcon css={link_icon} /></Button>
					</a>
				</figcaption>
			</figure>
		</div>
	)
}

export default Supervisor
